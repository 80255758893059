import * as Actions from "./actions";
import initialState from "../store/initialState";

//Actionsは現在の値で、actionは新しい値
//actionはおそらくdipatchによりreducersに渡されているので、importなど無しで使用できる
export const ReservationReducer = (state = initialState.reservations, action) => {
  switch (action.type) {
    case Actions.EDIT_RESERVATION:
      return {
        ...state,
        ...action.payload,
      };

    case Actions.GET_RESERVATION:
      return action.payload;

    case Actions.POST_RESERVATION:
      return {
        ...state,
        ...action.payload,
      };

    case Actions.DELETE_RESERVATION:
      return {
        // ...state,
        ...action.payload,
    };

    default:
      return state;
  }
};
