import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import Menu from "./Menu";
import Header from "./Header";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";

const Detail = (props) => {
  const history = useHistory();
  const [cookies, setCookie, removeCookie] = useCookies(["mitsuCookies"]);

  const token = cookies.mitsuCookies;
  if(token === undefined){
    history.push("/login");
  }
  const menuBlue = "bg-primary text-white me-3 px-2 py-1 rounded-pill";
  const menuGrey = "bg-secondary text-white me-3 px-2 py-1 rounded-pill";

  //Listページから渡された予約情報
  const data = props.location.state;

  let gender = "";
  if (data.gender === "male") {
    gender = "男性";
  } else {
    gender = "女性";
  }

  return (
    <>
    <div className="col-2 bg-dark text-white">
        <Menu />
      </div>
      <div className="col-10">
        <Header />
      <div className="container-fluid">
        <div className="col-8 flex-column">
          <div className="mt-3 d-flex flex-row justify-content-between">
            <div className="d-flex flex-row me-3 align-items-center">
              {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                fill="currentColor"
                className="bi bi-arrow-left-short"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"
                />
              </svg> */}
              <Link to="/">戻る</Link>
            </div>
            {/* <Link to={"/edit/" + data.code} data={data}> */}
            <Link
              to={{
                pathname: "/edit/" + data.code,
                state: data,
              }}
            >
              <div className="d-flex flex-row me-3 align-items-center">
                {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-pencil-square me-1"
                  viewBox="0 0 16 16"
                >
                  <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                  <path
                    fill-rule="evenodd"
                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                  />
                </svg> */}
                <div>編集</div>
              </div>
            </Link>
          </div>
          <div className="d-flex flex-row mt-3 justify-content-between align-items-center">
            <div className="d-flex flex-row">
              <div className="fs-4 me-3">{data.date}</div>
              <div className="fs-4">{data.time}</div>
            </div>
            <div className="mx-1 text-muted">最終更新：{data.updated_at}</div>
          </div>
          <div className="d-flex flex-row mt-3">
            <div className="me-4">
              <div className="text-muted" style={{ fontSize: "0.8rem" }}>
                予約番号
              </div>
              <div className="">{data.code}</div>
            </div>
            <div className="me-4">
              <div className="text-muted" style={{ fontSize: "0.8rem" }}>
                予約人数
              </div>
              <div className="">{data.number}人</div>
            </div>
            <div className="me-4">
              <div className="text-muted" style={{ fontSize: "0.8rem" }}>
                担当者
              </div>
              <div className="">{data.client}</div>
            </div>
          </div>
          <div className="mt-3">
            <div className="me-4 text-muted" style={{ fontSize: "0.8rem" }}>
              予約についての備考
            </div>
            <div className="">{data.note1}</div>
          </div>
          <div className="mt-3 d-flex flex-column">
            <div className="me-4 text-muted" style={{ fontSize: "0.8rem" }}>
              ご利用者様
            </div>
            <div className="d-flex flex-row border-bottom justify-content-between py-2">
              <div className="d-flex flex-row align-items-center">
                <div className="me-3">{data.patient}</div>
                <div className="me-3 text-muted" style={{ fontSize: "0.8rem" }}>
                  {gender}
                </div>
                <div className="me-3 text-muted" style={{ fontSize: "0.8rem" }}>
                  {data.room}号室
                </div>
              </div>
              <div className="d-flex flex-row align-items-center">
                <div className={data.menu_cut === "true" ? menuBlue : menuGrey}>カット</div>
                <div className={data.menu_face === "true" ? menuBlue : menuGrey}>顔そり</div>
                <div className={data.menu_shampoo === "true" ? menuBlue : menuGrey}>シャンプー</div>
                <div className={data.menu_coloring === "true" ? menuBlue : menuGrey}>カラー</div>
                <div className={data.menu_parm === "true" ? menuBlue : menuGrey}>パーマ</div>
              </div>
            </div>
          </div>
          <div>
            {/* <form>
              <label>POST API</label>
              <button onClick={handleSubmit}>Add</button>
            </form> */}
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default Detail;
