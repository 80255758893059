// import logo from "./logo.svg";
// import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Menu from "./components/Menu";
import Header from "./components/Header";
import Router from "./Router";
import { BrowserRouter } from "react-router-dom";
import history from "./history";
// import { useDispatch, useSelector } from "react-redux";
// import { editReservationAction } from "./reducks/reservations/actions";

function App() {
  return (
    <>
      <BrowserRouter>
        <div className="container-fluid">
          <div className="row vh-100">
            {/*  左側メニュー */}
            {/* <div className="col-2 bg-dark text-white">
              <Menu />
            </div> */}
            {/* <div className="col-10"> */}
              {/*  右側ヘッダー */}
              {/* <Header /> */}
              {/*  右側メイン */}
              <Router history={history} />
            {/* </div> */}
          </div>
        </div>
      </BrowserRouter>
    </>
  );
}

export default App;
