import { 
  createStore as reduxCreateStore, 
  combineReducers,
  applyMiddleware
} from "redux";
import { ReservationReducer } from "../reservations/reducers";
import { UserReducer } from "../users/reducers";
// import routeMiddleware from "connected-react-router";
import thunk from "redux-thunk";

//combineReducers:分割したReducersをまとめる。テーブルが複数あるときに有効。
export default function createStore() {
  return reduxCreateStore(
      combineReducers({ 
          reservations: ReservationReducer,
          users: UserReducer
      }),
      applyMiddleware(
        thunk
      )
  );
}
