import React, { useState, useEffect } from "react";

const NotFound = () => {
    return (
        <>
        <section className="vh-100 d-flex align-items-center justify-content-center" style={{backgroundColor: "#508bfc"}}>
            <h2 className="text-white display-1">404 Not Found</h2>
        </section>
      </>
    );
};

export default NotFound;