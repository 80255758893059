import React from "react";
import { Route, Switch } from "react-router-dom";

import List from "./components/List";
import Detail from "./components/Detail";
import Register from "./components/Register";
import Edit from "./components/Edit";
import Home from "./components/Home";
import Signin from "./components/Signin";
import Signup from "./components/Signup";
import NotFound from "./components/NotFound";

const Router = () => {
  return (
    // <BrowserRouter>
    <Switch>
      <Route exact path="/" component={List} />
      <Route path="/detail/:id" component={Detail} />
      <Route path="/register" component={Register} />
      <Route path="/edit/:id" component={Edit} />
      <Route path="/home" component={Home} />
      <Route exact path="/login" component={Signin} />
      <Route exact path="/signup" component={Signup} />
      <Route component={NotFound} />
    </Switch>
    // </BrowserRouter>
  );
};

export default Router;
