import React, { useState }from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useHistory } from "react-router-dom";


const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tr: {
    background: "#ffffff",
    "&:hover": {
      background: "#f1f1f1",
    },
    cursor: "pointer",
  },
  pointer: {
    "&:hover": {
      color: "#0B5ED7",
    },
    cursor: "pointer",
  }
});

const BasicTable = (props) => {
  const history = useHistory();
  const classes = useStyles();

  const clickDelete = props.clickDelete;
  const clickStatus = props.clickStatus;
  let listData = props.listData;

  let order = props.order;

  if(order === "date_asc"){
    const arr = [...listData];
    let result = arr.sort(function(a, b) {
      return (a.date < b.date) ? -1 : 1;
    });
    listData = result;
  }else if(order === "date_desc"){
    const arr = [...listData];
    let result = arr.sort(function(a, b) {
      return (a.date > b.date) ? -1 : 1;
    });
    listData = result;
  }else if(order === "created_at_asc"){
    const arr = [...listData];
    let result = arr.sort(function(a, b) {
      return (a.created_at < b.created_at) ? -1 : 1;
    });
    listData = result;
  }else if(order === "created_at_desc"){
    const arr = [...listData];
    let result = arr.sort(function(a, b) {
      return (a.created_at > b.created_at) ? -1 : 1;
    });
    listData = result;
  }else if(order === "updated_at_asc"){
    const arr = [...listData];
    let result = arr.sort(function(a, b) {
      return (a.updated_at < b.updated_at) ? -1 : 1;
    });
    listData = result;
  }else if(order === "updated_at_desc"){
    const arr = [...listData];
    let result = arr.sort(function(a, b) {
      return (a.updated_at > b.updated_at) ? -1 : 1;
    });
    listData = result;
  };


  if (listData === "undefined") {
    listData = [""];
  }

  const toDetail = (row) => {
    history.push("/detail/" + row.code, row);
  };

  const trashRow = (row) => {
    clickDelete(row);
  }

  const switchStatus = (row) => {
    clickStatus(row);
  }

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className="text-muted">ステータス</TableCell>
            <TableCell align="right" className="text-muted">
              予約番号
            </TableCell>
            <TableCell align="right" className="text-muted">
              予約日時
            </TableCell>
            <TableCell align="right" className="text-muted">
              人数
            </TableCell>
            <TableCell align="right" className="text-muted">
              担当者
            </TableCell>
            <TableCell align="right" className="text-muted">
              最終更新日
            </TableCell>
            <TableCell align="right" className="text-muted">
              操作
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(() => {
            if (listData !== "") {
              return listData.map((row) => (
                // <TableRow key={row.code} className={classes.tr}>
                <TableRow key={row.code}>
                  <TableCell align="left" onClick={() => switchStatus(row)}>
                  {(() => {
                    if (row.status !== "pending") {
                      return <button type="button" className="btn btn-success w-75">{row.status}</button>
                    }else{
                      return <button type="button" className="btn btn-danger w-75">{row.status}</button>
                    }
                  })()}
                  </TableCell>
                  <TableCell component="th" scope="row" align="right" onClick={() => toDetail(row)} className={classes.pointer}>
                    {row.code}
                  </TableCell>
                  <TableCell align="right">{row.date}</TableCell>
                  <TableCell align="right">{row.number}</TableCell>
                  <TableCell align="right">{row.client}</TableCell>
                  <TableCell align="right">{row.updated_at}</TableCell>
                  <TableCell align="right" className={classes.pointer} onClick={() => trashRow(row)}>
                  <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    width="16" 
                    height="16" 
                    fill="currentColor" 
                    className="bi bi-trash" 
                    viewBox="0 0 16 16"
                  >
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                    <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                  </svg>
                  </TableCell>
                </TableRow>
              ));
            } else {
              return  <TableRow>
                        <TableCell　align="center" colSpan="6">
                          <div className="d-flex flex-row justify-content-center">
                            <div className="me-2">loading...</div>
                            <div className="loader"></div>
                          </div>
                        </TableCell>
                      </TableRow>
            }
          })()}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BasicTable;
