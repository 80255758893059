export const GET_RESERVATION = "GET_RESERVATION";
export const POST_RESERVATION = "POST_RESERVATION";
export const EDIT_RESERVATION = "EDIT_RESERVATION";
export const DELETE_RESERVATION = "DELETE_RESERVATION";

export const getReservationAction = (useState) => {
  return {
    type: "GET_RESERVATION",
    payload: useState,
  };
};

export const editReservationAction = (useState) => {
  return {
    type: "EDIT_RESERVATION",
    payload: useState,
    // payload: {
    //   status: useState.status,
    //   code: useState.code,
    //   facility: useState.facility,
    //   date: useState.date,
    //   time: useState.time,
    //   number: useState.number,
    //   client: useState.client,
    //   pic1: useState.pic1,
    //   pic2: useState.pic2,
    //   pic3: useState.pic3,
    //   note1: useState.note1,
    //   patient: useState.patient,
    //   gender: useState.gender,
    //   room: useState.room,
    //   menu_cut: useState.menu_cut,
    //   menu_face: useState.menu_face,
    //   menu_coloring: useState.menu_coloring,
    //   menu_shampoo: useState.menu_shampoo,
    //   menu_parm: useState.menu_parm,
    //   note2: useState.note2,
    //   created_at: useState.created_at,
    //   updated_at: useState.updated_at,
    // },
  };
};

export const postReservationAction = (useState) => {
  return {
    type: "POST_RESERVATION",
    payload: useState,
    // payload: {
    //   status: useState.status,
    //   code: useState.code,
    //   facility: useState.facility,
    //   date: useState.date,
    //   time: useState.time,
    //   number: useState.number,
    //   client: useState.client,
    //   pic1: useState.pic1,
    //   pic2: useState.pic2,
    //   pic3: useState.pic3,
    //   note1: useState.note1,
    //   patient: useState.patient,
    //   gender: useState.gender,
    //   room: useState.room,
    //   menu_cut: useState.menu_cut,
    //   menu_face: useState.menu_face,
    //   menu_coloring: useState.menu_coloring,
    //   menu_shampoo: useState.menu_shampoo,
    //   menu_parm: useState.menu_parm,
    //   note2: useState.note2,
    //   created_at: useState.created_at,
    //   updated_at: useState.updated_at,
    // },
  };
};

export const deleteReservationAction = (useState) => {
  return {
    type: "DELETE_RESERVATION",
    payload: useState,
    // payload: {
    //   status: "",
    //   code: "",
    //   facility: "",
    //   date: "",
    //   time: "",
    //   number: "",
    //   client: "",
    //   pic1: "",
    //   pic2: "",
    //   pic3: "",
    //   note1: "",
    //   patient: "",
    //   gender: "",
    //   room: "",
    //   menu_cut: "",
    //   menu_face: "",
    //   menu_coloring: "",
    //   menu_shampoo: "",
    //   menu_parm: "",
    //   note2: "",
    //   created_at: "",
    //   updated_at: "",
    // },
  };
};
