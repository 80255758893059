export const SIGN_IN = "SIGN_IN";
export const SIGN_OUT = "SIGN_OUT";

export const signIn = (useState) => {
  return {
    type: "SIGN_IN",
    payload: useState,
    //isSignedIn: true
    //email: taro@example.com
    //password: password
    //token: xxxx-xxxx
  };
};

export const signOut = (useState) => {
  return {
    type: "SIGN_OUT",
    payload: useState,
    //isSignedIn: false
    //email: ""
    //password: ""
    //token: ""
  };
};