import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import authentication from "./Authentication";
import { useDispatch, useSelector } from "react-redux";
import { signIn } from "../reducks/users/actions";
import { useCookies } from "react-cookie";

const Signin = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const [state, setState] = useState({
        isSignedIn: false,
        email:"",
        password:"",
        token:""
    });

    const [message, setMessage] = useState("");

    const [cookies, setCookie, removeCookie] = useCookies(["mitsuCookies"]);

    const onChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        const data = {};
        data[name] = value;
    
        setState({ ...state, ...data });
    };

    const clickLogin = (event) => {
        (async () => {
            const email = state.email;
            const password = state.password;
            let res = "";
            try{
                res = await authentication(email, password);
                
                const data = {
                    isSignedIn:true,
                    email:email,
                    // password:password,
                    token:res.data.token,
                    expiresIn:res.data.expiresIn
                }

                setCookie("mitsuCookies", res.data.token, {maxAge:res.data.expiresIn});

                if(res.status === 200){
                    dispatch(signIn(data));
                    history.push("/");
                }
            }catch(error){
                setMessage("Username or password is incorrect..");
            }
        })();
    };

    return (
        <>
        {/* <form>
            <h3>Sign In</h3>
            <div className="mb-3">
                <div className="form-group">
                    <label>Email address</label>
                    <input onChange={onChange} type="email" name="email" className="form-control" placeholder="Enter email" value={state.email}/>
                </div>
            </div>
            <div className="mb-3">
                <div className="form-group">
                    <label>Password</label>
                    <input onChange={onChange} type="password" name="password" className="form-control" placeholder="Enter password" value={state.password}/>
                </div>
            </div>
            <div onClick={clickLogin} className="d-flex flex-row">
              <Button size="medium" className="me-2">ログインする</Button>
            </div>
        </form> */}
        <section className="vh-100" style={{backgroundColor: "#508bfc"}}>
            <div className="container py-5 h-100">
                <div className="row d-flex justify-content-center align-items-center h-100">
                    <div className="col-12 col-md-8 col-lg-6 col-xl-5">
                    <div className="card shadow-2-strong" style={{borderRadius: "1rem"}}>
                        <div className="card-body p-5 text-center">
            
                        <h3 className="mb-5">Sign in</h3>
            
                        <div className="form-outline mb-4">
                            {/* <label>Email address</label> */}
                            <input onChange={onChange}　name="email" type="email" id="typeEmailX-2" className="form-control form-control-lg" placeholder="Enter email" value={state.email}/>
                        </div>
            
                        <div className="form-outline mb-4">
                            {/* <label>Password</label> */}
                            <input onChange={onChange}　name="password" type="password" id="typePasswordX-2" className="form-control form-control-lg" placeholder="Enter password" value={state.password}/>
                        </div>
            
                        <button onClick={clickLogin}　className="btn btn-primary btn-lg btn-block" type="submit">Login</button>
            
                        </div>
                        {(() => {
                            if (message !== "") {
                                return <div className="text-center text-red mb-4">{message}</div>;
                            }
                        })()}
                    </div>
                    </div>
                </div>
                <div className="mt-auto mb-3 text-center text-white">© mitsublo.com</div>
            </div>
        </section>
      </>
    );
};

export default Signin;