import React, { useState, useEffect } from "react";
import BasicTable from "./BasicTable";
import SimpleSelect from "./SimpleSelect";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { getReservationAction } from "../reducks/reservations/actions";
import { deleteReservationAction } from "../reducks/reservations/actions";
import "../App.css";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import Menu from "./Menu";
import Header from "./Header";
import { editReservationAction } from "../reducks/reservations/actions";

const List = () => {
  const history = useHistory();
  const [cookies, setCookie, removeCookie] = useCookies(["mitsuCookies"]);
  const dispatch = useDispatch();
  let selector = useSelector((state) => {
    return state;
  });

  const [listData, setListData] = useState("");
  const [order, setOrder] = useState("");

  const changeOrder = (order) => {
    setOrder(order);
  };

  useEffect(() => {
    // const token = selector.users.token;
    const token = cookies.mitsuCookies;

    //storeにtokenがなければログインページに遷移する
    //リフレッシュするとstoreの値は消える
    if(token !== undefined){
      //useEffectの中で条件分岐しないと、無限ループが発生する。
      if (selector.reservations.length === 0) {
        let res = "";
        (async () => {
          try{
            res = await axios.get("https://cus-api.mitsublo.com/api/get", {
              headers: {
                Authorization: `Bearer ${token}`,
              }
            });
          }catch(error){
            history.push("/login");
          };
          const data = res.data;
          setListData(data);
          if (data !== undefined) {
            dispatch(getReservationAction(data));
          }
        })();
      } else {
        //storeにある最新のデータを取得する
        const data = selector.reservations;

        //dataを配列に加工しないと、BasicTableのmap関数で使用できない
        const array = [];
        for(let key in data ){
          array.push(data[key]);
        };

        // setListData(data);
        setListData(array);
      }
    }else{
      history.push("/login");
    }
  }, [dispatch, selector.reservations.length, selector.reservations]);
  // }, [dispatch]);

  const deleteRow = (row) => {
    // const token = selector.users.token;
    const token = cookies.mitsuCookies;

    //storeにtokenがなければログインページに遷移する
    //リフレッシュするとstoreの値は消える
    if(token === undefined){
      history.push("/login");
    }

    (async () => {
      let res = "";
      try{
        res = await axios.post("https://cus-api.mitsublo.com/api/delete", row, {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        });
      }catch(error){
        history.push("/login");
      };
      const data = res.data;
      setListData(data);
      dispatch(deleteReservationAction(data));

      //一覧に遷移する
      history.push("/");
    })();
  };

  const changeStatus = (row) => {
    if(row.status === "pending"){
      row.status = "complete";
    }else{
      row.status = "pending";
    }

    const token = cookies.mitsuCookies;

    //storeにtokenがなければログインページに遷移する
    //リフレッシュするとstoreの値は消える
    if(token === undefined){
      history.push("/login");
    }

    (async () => {
      let res = "";
      try{
        res = await axios.post("http://localhost:3001/api/update", row, {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        });
      }catch(error){
        history.push("/login");
      };
      const data = res.data;
      setListData(data);
      dispatch(editReservationAction(data));

      //一覧に遷移する
      history.push("/");
    })();
  };

  return (
    <>
      <div className="col-2 bg-dark text-white">
        <Menu />
      </div>
      <div className="col-10">
        <Header />
        <div className="mt-3 mb-3">
          <div className="d-flex flex-row justify-content-end mt-3">
            <SimpleSelect
              items={[
                { date_asc: "日付-昇順" }, 
                { date_desc: "日付-降順" }, 
                { created_at_asc: "作成日-昇順" }, 
                { created_at_desc: "作成日-降順" },
                { updated_at_asc: "更新日-昇順" }, 
                { updated_at_desc: "更新日-降順" },
              ]}
              data={order}
              clickSelector={(order) => changeOrder(order)}
            />
          </div>
          <BasicTable 
            listData={listData} 
            clickDelete={(row) => deleteRow(row)}
            clickStatus={(row) => changeStatus(row)}
            order={order} 
          />
        </div>
      </div>
    </>
  );
};

export default List;
