import React, { useState }from "react";
import { makeStyles } from "@material-ui/core/styles";
import SimpleSelect from "./SimpleSelect";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import "bootstrap/dist/css/bootstrap.min.css";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { Button } from "react-bootstrap";
import { postReservationAction } from "../reducks/reservations/actions";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import Menu from "./Menu";
import Header from "./Header";

const useStyles = makeStyles((theme) => ({
  textField: {
    padding: "1rem 0",
    width: "200",
  },
  textareaAutosize: {
    border: "1px solid #C4C4C4",
    borderRadius: "4px",
  },
}));

const Register = () => {
  const dispatch = useDispatch();
  const [cookies] = useCookies(["mitsuCookies"]);
  const token = cookies.mitsuCookies;
  let selector = useSelector((state) => {
    return state;
  });
  const history = useHistory();
  const classes = useStyles();
  const [state, setState] = useState({
    status: "",
    date: "",
    time: "",
    client: "",
    note1: "",
    patient: "",
    pic1: false,
    pic2: false,
    pic3: false,
    facility: "",
    gender: "",
    room: "",
    menu_coloring: false,
    menu_cut: false,
    menu_face: false,
    menu_parm: false,
    menu_shampoo: false,
    note2: "",
  });

  if(token === undefined){
    history.push("/login");
  }

  //二重クリック防止
  const [flag, setFlag] = useState(false);

  const onChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const data = {};
    data[name] = value;

    setState({ ...state, ...data });
  };

  const changeDate = (newDate) => {
    const name = "date";
    const value = newDate;
    const data = {};
    data[name] = value;
    setState({ ...state, ...data });
  };

  const changeFacility = (facility) => {
    const name = "facility";
    const value = facility;
    const data = {};
    data[name] = value;
    setState({ ...state, ...data });
  };

  const changeGender = (gender) => {
    const name = "gender";
    const value = gender;
    const data = {};
    data[name] = value;
    setState({ ...state, ...data });
  };

  const changeCheckBox = (event) => {
    const name = event.target.name;
    const value = !state[name];
    const data = {};
    data[name] = value;
    setState({ ...state, ...data });
  };

  const clickRegister = (event) => {
    (async () => {
      let res = "";
      let new_flag = !flag;
      setFlag(new_flag);
      try{
        res = await axios.post("https://cus-api.mitsublo.com/api/register", state, {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        });
      }catch(error){
        history.push("/login");
      };
      const data = res.data;
      dispatch(postReservationAction(data));
      
      //ここで変数flagを使用すると古い初期値を使用するので、変数new_flagを使用する
      setFlag(!new_flag);

      //一覧に遷移する
      history.push("/");
    })();
  };

  return (
    <>
      <div className="col-2 bg-dark text-white">
        <Menu />
      </div>
      <div className="col-10">
        <Header />
        <div className="container-fluid">
          <div className="col-8 flex-column">
            <div className="fs-5 mt-3">予約情報（登録）</div>
            <div>
              <div className="text-muted mt-3" style={{ fontSize: "0.8rem" }}>
                施設
              </div>
              <SimpleSelect 
                items={[{ f1: "施設1" }, { f2: "施設2" }, { f3: "施設3" }, { f4: "施設4" }]} 
                name="facility"
                data={state.facility}
                clickSelector={(facility) => changeFacility(facility)}
              />
            </div>
            <div className="d-flex flex-row">
              <div className="d-flex flex-column  me-3">
                <div className="text-muted mt-3" style={{ fontSize: "0.8rem" }}>
                  日付
                </div>
                <div className="mt-3">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      locale="ja"
                      dateFormat="yyyy/MM/dd"
                      value={state.date}
                      onChange={changeDate}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </div>
              </div>
              <div className="me-3">
                <div className="text-muted mt-3" style={{ fontSize: "0.8rem" }}>
                  時間
                </div>
                <div className="mt-3">
                  <form noValidate>
                    <TextField
                      name="time"
                      value={state.time}
                      onChange={onChange}
                      id="time"
                      type="time"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: 300, // 5 min
                      }}
                    />
                  </form>
                </div>
              </div>
              <div className="d-flex flex-column">
                <div className="text-muted mt-3" style={{ fontSize: "0.8rem" }}>
                  お客様
                </div>
                <form className={classes.textField} noValidate autoComplete="off">
                  <TextField 
                    id="outlined-basic"
                    variant="outlined"
                    name="client"
                    value={state.client}
                    onChange={onChange}
                  />
                </form>
              </div>
            </div>
            <div className="d-flex flex-column ">
              <div>
                <div className="text-muted mt-3" style={{ fontSize: "0.8rem" }}>
                  担当者リスト
                </div>
              </div>
              <div className="d-flex flex-row mt-2">
                <div className="form-check me-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    onChange={changeCheckBox}
                    name="pic1"
                    checked={state.pic1}
                    id="flexCheckDefault"
                  />
                  <label className="form-check-label">担当者A</label>
                </div>
                <div className="form-check me-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    onChange={changeCheckBox}
                    name="pic2"
                    checked={state.pic2}
                    id="flexCheckDefault"
                  />
                  <label className="form-check-label">担当者B</label>
                </div>
                <div className="form-check me-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    onChange={changeCheckBox}
                    name="pic3"
                    checked={state.pic3}
                    id="flexCheckDefault"
                  />
                  <label className="form-check-label">担当者C</label>
                </div>
              </div>
            </div>
            <div className="d-flex flex-column">
              <div className="text-muted mt-3 mb-3" style={{ fontSize: "0.8rem" }}>
                備考
              </div>
              <TextareaAutosize
                className={classes.textareaAutosize}
                minRows={4}
                maxRows={4}
                aria-label="maximum height"
                name="note1"
                value={state.note1}
                onChange={onChange}
              />
            </div>
            <div className="mt-5 flex-d flex-column mb-5">
              <div className="fs-5 mt-3">ご利用者様情報</div>
              <div className="d-flex flex-row">
                <div className="d-flex flex-column me-3">
                  <div className="d-flex flex-row mt-3">
                    <div className="text-muted me-2" style={{ fontSize: "0.8rem" }}>
                      お名前
                    </div>
                    <div style={{ fontSize: "0.8rem", color: "red" }}>必須</div>
                  </div>
                  <form className={classes.textField} noValidate autoComplete="off">
                    <TextField 
                      id="outlined-basic"
                      variant="outlined"
                      name="patient"
                      value={state.patient}
                      onChange={onChange}
                    />
                  </form>
                </div>
                <div className="me-3">
                  <div className="text-muted mt-3 me-3" style={{ fontSize: "0.8rem" }}>
                    性別
                  </div>
                  <SimpleSelect 
                    items={[{ male: "男性" }, { female: "女性" }]} 
                    name="gender"
                    data={state.gender}
                    clickSelector={(gender) => changeGender(gender)}
                  />
                </div>
                <div className="d-flex flex-column me-3">
                  <div className="text-muted mt-3" style={{ fontSize: "0.8rem" }}>
                    部屋番号
                  </div>
                  <form className={classes.textField} noValidate autoComplete="off">
                    <TextField 
                      id="outlined-basic"
                      variant="outlined"
                      name="room"
                      value={state.room}
                      onChange={onChange}
                    />
                  </form>
                </div>
              </div>
              <div className="d-flex flex-row mt-2">
                <div className="form-check me-3">
                <input
                    className="form-check-input"
                    type="checkbox"
                    name="menu_cut"
                    value="cut"
                    onChange={changeCheckBox}
                    checked={state.menu_cut}
                    id="flexCheckDefault"
                  />
                  <label className="form-check-label">カット</label>
                </div>
                <div className="form-check me-3">
                <input
                    className="form-check-input"
                    type="checkbox"
                    name="menu_face"
                    value="face"
                    onChange={changeCheckBox}
                    checked={state.menu_face}
                    id="flexCheckDefault"
                  />
                  <label className="form-check-label">顔そり</label>
                </div>
                <div className="form-check me-3">
                <input
                    className="form-check-input"
                    type="checkbox"
                    name="menu_shampoo"
                    value="shampoo"
                    onChange={changeCheckBox}
                    checked={state.menu_shampoo}
                    id="flexCheckDefault"
                  />
                  <label className="form-check-label">シャンプー</label>
                </div>
                <div className="form-check me-3">
                <input
                    className="form-check-input"
                    type="checkbox"
                    name="menu_coloring"
                    value="coloring"
                    onChange={changeCheckBox}
                    checked={state.menu_coloring}
                    id="flexCheckDefault"
                  />
                  <label className="form-check-label">カラー</label>
                </div>
                <div className="form-check me-3">
                <input
                    className="form-check-input"
                    type="checkbox"
                    name="menu_parm"
                    value="Parm"Z
                    onChange={changeCheckBox}
                    checked={state.menu_parm}
                    id="flexCheckDefault"
                  />
                  <label className="form-check-label">パーマ</label>
                </div>
              </div>
              <div className="d-flex flex-column mb-5">
                <div className="text-muted mt-3 mb-3" style={{ fontSize: "0.8rem" }}>
                  備考
                </div>
                <TextareaAutosize
                  className={classes.textareaAutosize}
                  minRows={3}
                  maxRows={3}
                  aria-label="maximum height"
                  name="note2"
                  value={state.note2}
                  onChange={onChange}
                />
              </div>
              <div onClick={clickRegister} className="d-flex flex-row">
                <Button size="medium" disabled={flag} className="me-2">登録する</Button>
                {(() => {
                  if(flag===true){
                    return  <div className="d-flex align-items-center">
                              <div className="loader"></div>
                            </div>
                  }
                })()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
