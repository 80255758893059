import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 200,
    padding: "1rem 0",
  },
}));

const SimpleSelect = (props) => {
  const classes = useStyles();

  const { items, data, clickSelector } = props;

  const onChange = (event) => {
    clickSelector(event.target.value);
  };

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      {/* <Select labelId="select-demo" id="order" value={value} onChange={handleChange}> */}
      <Select labelId="select-demo" id="order" value={data} onChange={onChange}>
        {items.map((item, index) => {
          return (
            <MenuItem value={`${Object.keys(item)}`} key={index}>
              {Object.values(item)}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default SimpleSelect;
