import React, { useState } from "react";
import Menu from "./Menu";
import Header from "./Header";
import { useCookies } from "react-cookie";
import { useHistory } from "react-router-dom";

const Home = () => {
  const history = useHistory();
  const [cookies, setCookie, removeCookie] = useCookies(["mitsuCookies"]);

  const token = cookies.mitsuCookies;
  if(token === undefined){
    history.push("/login");
  }

  return (
    <>
      <div className="col-2 bg-dark text-white">
        <Menu />
      </div>
      <div className="col-10">
        <Header />
        <div>HOME</div>
      </div>
    </>
  );
};

export default Home;
