import React from "react";
import { Button } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
}));

const Header = () => {
  const classes = useStyles();

  return (
    <div className="border-bottom">
      <Link to="/register">
        <div className="d-flex flex-row justify-content-end">
          <Button size="medium" className={classes.margin}>
            新しく予約する
          </Button>
        </div>
      </Link>
    </div>
  );
};

export default Header;
