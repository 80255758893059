const initialState = {
  reservations: [],
  users: [{
    isSignedIn: false,
    email:"",
    password:"",
    token:"",
  }]
};

export default initialState;
